import { close as destinationPickerClose } from './destination-picker.js';
import { close as airportPickerClose } from './airport-picker.js';
import { submit as dynamicSearchSubmit } from "./dynamic-search.js"
import { breakpoints, lockout } from "./utilities.js";
import { getSearchBarOptions as searchBarGetSearchBarOptions, searchBlockPickerClose, searchBlockPickerOpen } from "./search-bar.js"
import { default as Mustache } from "./mustache.js"

let template;

const elements = {
	pageWrappers: $('.l-page, .l-page__inner, .m-grid, .l-nav__menu__item--simple'),
	popups: null
}

const state = {
	wrapper: null,
	html: null,
	isPopup: true,

	store: function(popup) {
		state.wrapper = popup.find('.js-folks-selector-popup-inputs-wrapper');
		state.html = state.wrapper.html();
	},

	restore: function() {
		if (state.wrapper === null || state.html === null) return;

		state.wrapper = null;
		state.html = null;
		state.isPopup = true;
	},

}

const close = function(wrapper, doSearch) {

	elements.pageWrappers.removeAttr('style');
	$('.js-folks-selector-trigger').removeClass('is-active');

	if (state.isPopup) {
		lockout.off();
	}

	elements.popups.hide();

	var searchBlockWrapper = wrapper.closest('.js-search-block-wrapper');
	if (searchBlockWrapper.length) {
		applyFlattenedRoomsToOtherTabIfRequired(searchBlockWrapper);
	}

	// booking widget only
	if (doSearch) {

		var submit = wrapper.find('.js-booking-widget-generic-submit');
		if (submit.length) {
			submit.trigger('click');
		}
		// dynamic search

		if (isInsideDynamicSearchArea(wrapper)) dynamicSearchSubmit(false);

	} else {

		state.restore();

	}
	$('.l-nav').css('z-index', '13');
}

const isInsideDynamicSearchArea = function(element) {
	return element.parents('.js-dynamic-search').length;
}

const showMessaging = function(wrapper) {

	var inputsRow = wrapper.find('.js-folks-selector-popup-row'),
		message = 'sharing ' + inputsRow.length + ' rooms',
		messageElement = wrapper.find('.js-folks-selector-trigger-message');

	if (inputsRow.length > 1) {

		if (messageElement.length) {
			messageElement.html(message);
		} else {
			wrapper.find('.js-folks-selector-trigger:not(.js-folks-selector-trigger-standalone)')
				.after('<p class="js-folks-selector-trigger-message">' + message + '</p>');
		}

	} else {

		if (messageElement.length) {
			messageElement.remove();
		}

	}
}

const calculatePeople = function(wrapper) {

	var adults = 0,
		children = 0,
		infants = 0,
		inputsRow = wrapper.find('.js-folks-selector-popup-row');

	inputsRow.each(function() {

		var inputs = $(this);
		adults += parseInt(inputs.find('.js-folks-selector-popup-row-adults').val());
		children += parseInt(inputs.find('.js-folks-selector-popup-row-children').val());
		infants += parseInt(inputs.find('.js-folks-selector-popup-row-infants').val());

	});

	return { adults: adults, children: children, infants: infants };
}

const calculatePeopleAndShowMessage = function(wrapper) {

	var peopleCount = calculatePeople(wrapper);

	var adultNumber = wrapper.find('.js-folks-selector-trigger-adults'),
		childrenNumber = wrapper.find('.js-folks-selector-trigger-children'),
		infantsNumber = wrapper.find('.js-folks-selector-trigger-infants');

	adultNumber.text(peopleCount.adults);
	childrenNumber.text(peopleCount.children);
	infantsNumber.text(peopleCount.infants);

	showMessaging(wrapper);

}

const setOccupancyLimits = function(wrapper, minOccupancy, maxOccupancy, absoluteMaxChildOccupancy) {
	wrapper.find('.js-adults').each(function() {
		var row = $(this);
		row.attr('min', minOccupancy);
		row.attr('max', maxOccupancy);
		row.attr('data-combined-max', maxOccupancy);
	});

	var maxChildOccupancy = maxOccupancy > absoluteMaxChildOccupancy ? absoluteMaxChildOccupancy : maxOccupancy;
	wrapper.find('.js-children').each(function() {
		var row = $(this);
		row.attr('max', maxChildOccupancy);
		row.attr('data-combined-max', maxOccupancy);
	});
}

const setMaxRooms = function(wrapper, maxRooms) {
	wrapper.find('.js-folks-selector-wrapper-limit').val(maxRooms);
	showOrHideAddRoombutton(wrapper);
}

const parseForm = function(wrapper) {

	var inputsRow = wrapper.find('.js-folks-selector-popup-row');
	var formData = [];

	inputsRow.each(function() {

		var inputs = $(this);
		var adults = parseInt(inputs.find('.js-folks-selector-popup-row-adults').val());
		var children = parseInt(inputs.find('.js-folks-selector-popup-row-children').val());
		var infants = parseInt(inputs.find('.js-folks-selector-popup-row-infants').val());

		var adultMaxOccupancy = parseInt(inputs.find('.js-folks-selector-popup-row-adults').attr('max')),
			childMaxOccupancy = parseInt(inputs.find('.js-folks-selector-popup-row-children').attr('max')),
			infantMaxOccupancy = parseInt(inputs.find('.js-folks-selector-popup-row-infants').attr('max'));

		var adultMinOccupancy = parseInt(inputs.find('.js-folks-selector-popup-row-adults').attr('min'));

		formData.push({
			AdultCount: adults,
			ChildCount: children,
			InfantCount: infants,
			AdultMaxOccupancy: adultMaxOccupancy,
			AdultMinOccupancy: adultMinOccupancy,
			ChildMaxOccupancy: childMaxOccupancy,
			InfantMaxOccupancy: infantMaxOccupancy,
			RoomNumber: formData.length + 1,
			FormNumber: formData.length,
			Id: guid.create()
		});

	});

	return formData;

}

const guid = {
	s4: function() {
		return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
	},

	create: function() {

		return guid.s4() +
			guid.s4() +
			'-' +
			guid.s4() +
			'-' +
			guid.s4() +
			'-' +
			guid.s4() +
			'-' +
			guid.s4() +
			guid.s4() +
			guid.s4();
	}
}

const addRoom = function(wrapper, inputsWrapper, popup) {

	var roomConfigQueryStringName = wrapper.find('.js-folks-selector-room-config-query-string-name').val();

	var inputsRow = wrapper.find('.js-folks-selector-popup-row');

	var formData = parseForm(wrapper);
	formData.push({
		RoomNumber: inputsRow.length + 1,
		FormNumber: inputsRow.length,
		Id: guid.create(),
		AdultCount: 2,
		ChildCount: 0,
		InfantCount: 0,
		AdultMinOccupancy: formData[0].AdultMinOccupancy,
		AdultMaxOccupancy: formData[0].AdultMaxOccupancy,
		ChildMaxOccupancy: formData[0].ChildMaxOccupancy,
		InfantMaxOccupancy: formData[0].InfantMaxOccupancy
	});

	var renderedRow = Mustache.render(template, { Rooms: formData, RoomConfigQueryStringName: roomConfigQueryStringName, IncludeWrappingHtml: false, MultipleRoomMode: true });
	inputsWrapper.html(renderedRow);

	showOrHideAddRoombutton(wrapper);
	showOrHideContactUsMessage(wrapper);

	popup.find('.m-folks-selector__popup__row__room-number').last().focus();

	calculatePeopleAndShowMessage(wrapper);

}

const showOrHideContactUsMessage = function(wrapper) {

	var inputsRow = wrapper.find('.js-folks-selector-popup-row');
	var contactUsMessage = wrapper.find('.js-folks-selector-popup-contact');

	if (inputsRow.length === 9) {
		contactUsMessage.show();
	} else {
		contactUsMessage.hide();
	}
}

const showOrHideAddRoombutton = function(wrapper) {

	var inputsRows = wrapper.find('.js-folks-selector-popup-row');

	if (shouldHideAddRoomButton(wrapper, inputsRows.length)) {
		hideAddRoomButton(wrapper);
	} else {
		showAddRoomButton(wrapper);
	}

}

const shouldHideAddRoomButton = function(wrapper, currentLength) {
	var limit = parseInt(wrapper.find('.js-folks-selector-wrapper-limit').val());

	if (currentLength >= limit) {
		return true;
	}

	return false;
}

const hideAddRoomButton = function(wrapper) {
	wrapper.find('.js-search-add-room').hide();
}

const showAddRoomButton = function(wrapper) {
	wrapper.find('.js-search-add-room').show();
}

const removeRoom = function(wrapper, inputsWrapper) {

	var roomConfigQueryStringName = wrapper.find('.js-folks-selector-room-config-query-string-name').val();

	var formData = parseForm(wrapper);
	var renderedRow = Mustache.render(template, { Rooms: formData, RoomConfigQueryStringName: roomConfigQueryStringName, IncludeWrappingHtml: false, MultipleRoomMode: true });
	inputsWrapper.html(renderedRow);

	showOrHideAddRoombutton(wrapper);
	showOrHideContactUsMessage(wrapper);
	calculatePeopleAndShowMessage(wrapper);
}

const applyFlattenedRoomsToOtherTabIfRequired = function(searchBlockWrapper) {

	var activeTabWrapper = searchBlockWrapper.find('.js-tabs-tab:visible');
	var inactiveTabWrapper = searchBlockWrapper.find('.js-tabs-tab:hidden');

	var inactiveTabInputsWrapper = inactiveTabWrapper.find('.js-folks-selector-popup-inputs-wrapper');

	var activeTabPeopleCount = calculatePeople(activeTabWrapper);
	var inactiveTabPeopleCount = calculatePeople(inactiveTabWrapper);

	if (inactiveTabRequiresUpdating(activeTabPeopleCount, inactiveTabPeopleCount))
		flattenRooms(inactiveTabWrapper, inactiveTabInputsWrapper, activeTabPeopleCount);

}

const inactiveTabRequiresUpdating = function(firstPeopleCount, secondPeopleCount) {
	return firstPeopleCount.adults !== secondPeopleCount.adults ||
		firstPeopleCount.children !== secondPeopleCount.children ||
		firstPeopleCount.infants !== secondPeopleCount.infants;
}


const flattenRooms = function(wrapper, inputsWrapper, peopleCounts) {

	var formData = parseForm(wrapper);
	var firstRow = formData[0];
	firstRow.AdultCount = peopleCounts.adults;
	firstRow.ChildCount = peopleCounts.children;
	firstRow.InfantCount = peopleCounts.infants;

	var roomConfigQueryStringName = wrapper.find('.js-folks-selector-room-config-query-string-name').val();
	var renderedRow = Mustache.render(template, { Rooms: [firstRow], RoomConfigQueryStringName: roomConfigQueryStringName, IncludeWrappingHtml: false, MultipleRoomMode: true });
	inputsWrapper.html(renderedRow);

	calculatePeopleAndShowMessage(wrapper);
	showOrHideAddRoombutton(wrapper);
}

const init = function() {

	template = $('#folkSelectorTemplate');

	if (!template.length) return;

	template = template.html();
	// make the overlay clickable to close the form

	window.site.body.click(function(e) {

		if ($('.js-folks-selector-popup').is(':visible')) {

			if (!($(e.target).parents('.m-folks-selector__popup').length > 0)) {

				var wrapper = $('.js-folks-selector-popup-inputs-wrapper:visible').closest('.js-folks-selector-wrapper');
				calculatePeopleAndShowMessage(wrapper);

				var shouldSearch = !$(e.target).hasClass('js-datepicker-wrapper') && !$(e.target).hasClass('js-destination-picker-facade') && !$(e.target).hasClass('js-datepicker-input') && !$(e.target).hasClass('js-datepicker-trigger') && !$(e.target).hasClass('js-airport-picker-trigger') && !$(e.target).hasClass('js-airport-picker-facade') && !$('.js-navsearch-sub .js-folks-selector-wrapper').is(':visible');
				close(wrapper, shouldSearch);
			}
			e.stopPropagation();
		}
	});

	elements.popups = $('.js-folks-selector-popup');

	const initSelectors = () => {

		$('.js-folks-selector-wrapper:not(.js-folks-selector-wrapper-done)').each(function() {
			var wrapper = $(this),
				popup = wrapper.find('.js-folks-selector-popup'),
				closePopup = wrapper.find('.js-folks-selector-popup-close'),
				confirmChoice = wrapper.find('.js-folks-selector-popup-submit'),
				inputsWrapper = wrapper.find('.js-folks-selector-popup-inputs-wrapper'),
				addRoomRow = wrapper.find('.js-folks-selector-popup-add-room'),
				contactUsMessage = wrapper.find('.js-folks-selector-popup-contact'),
				destination = wrapper.find('.js-folks-selector-destination'),
				destinationDisabledState = destination.is(':disabled'),
				departing = wrapper.find('.js-folks-selector-departing'),
				departingDisabledState = departing.is(':disabled'),
				duration = wrapper.find(".js-folks-selector-duration"),
				durationDisabledState = duration.is(':disabled');

			var inputsRow = wrapper.find('.js-folks-selector-popup-row');
			if (shouldHideAddRoomButton(wrapper, inputsRow.length)) {
				hideAddRoomButton(wrapper);
			}

			if (inputsRow.length === 9) {
				contactUsMessage.show();
			} else {
				contactUsMessage.hide();
			}
			if (destination.length && departing.length) {
				updateFormDisabledState(false, false, false);
				updateFolksSelector();
				updateFormDisabledState(destinationDisabledState, departingDisabledState, durationDisabledState);
			}

			// destination

			function updateFormDisabledState(destinationDisabled, departingDisabled, durationDisabled) {
				destination.prop('disabled', destinationDisabled);
				departing.prop('disabled', departingDisabled);
				duration.prop('disabled', durationDisabled);
			}

			// enableDatepicker bool - if wrapper resides in a disabled tab don't enable the datepicker

			var enableDatepicker = wrapper.closest('.js-tabs-tab').is('.js-tabs-tab-disabled');

			function updateFolksSelector() {
				console.log('updateFolksSelector')
				searchBarGetSearchBarOptions(
					wrapper.find('input,select'),
					departing,
					duration,
					enableDatepicker,
					wrapper);
			}

			// popup open

			wrapper.on('click keypress', '.js-folks-selector-trigger', function(e) {

				window.site.body.trigger('closeDatePicker');

				if (e.type === 'click' || ((e.type === 'keypress') && e.which === 13)) {

					// if it is already open close
					if (wrapper.find('.js-folks-selector-popup').is(':visible')) {
						return;
					}

					// hide destinations and airport picker
					destinationPickerClose(false);
					airportPickerClose(false);

					// lock the page + show the popup
					var isPopup = state.isPopup;
					if ($(e.currentTarget).hasClass('js-folks-selector-trigger--no-popup-desktop') && window.site.win.width() >= breakpoints.tablet) {
						isPopup = false;
					}
					if (isPopup) {
						lockout.on();
						searchBlockPickerOpen($(e.currentTarget));
					}

					// close any currently open
					if ($('.js-folks-selector-popup:visible').length) {
						state.isPopup = isPopup;
						close(wrapper, false);
					}

					wrapper.find('.js-folks-selector-popup').show();
					// store the current state
					state.store(popup);
					state.isPopup = isPopup;
					showOrHideAddRoombutton(wrapper);
					showOrHideContactUsMessage(wrapper);
					popup.find('.m-folks-selector__popup__row__room-number').first().focus();


					wrapper.find('.js-folks-selector-trigger').addClass('is-active');
					wrapper.find('.js-quantity-button:visible').first().focus();

					return false;

				}

			});

			// popup close click

			closePopup.on('click', function(e) {

				calculatePeopleAndShowMessage(wrapper);
				close(wrapper, true);
				searchBlockPickerClose();
				return false;

			});

			// confirm choice
			confirmChoice.on('click', function() {

				calculatePeopleAndShowMessage(wrapper);
				close(wrapper, true);
				return false;

			});

			// add a new room row
			addRoomRow.on('click', function() {
				addRoom(wrapper, inputsWrapper, popup);
				return false;

			});

			// remove a room

			inputsWrapper.on('click', '.js-folks-selector-popup-remove', function() {

				$(this).closest('.js-folks-selector-popup-row').remove();
				removeRoom(wrapper, inputsWrapper);
				return false;

			});

			// show messaging on load
			showMessaging(wrapper);

			// done
			wrapper.addClass('js-folks-selector-wrapper-done');

		});
	}

	setTimeout(initSelectors, 400);  //Fixes a race condition, cant use an event based solution with the way the pickers are written currently.

}

export default init;
export { setOccupancyLimits, setMaxRooms, showMessaging, init, calculatePeopleAndShowMessage, close };
